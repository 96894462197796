import { Button } from "@mui/material";
import Card from "../Shared/Card";
import CardBody from "../Shared/CardBody";
import CardFooter from "../Shared/CardFooter";
import KeyValue from "../Shared/KeyValue";
import { useNavigate } from "react-router-dom";

export default function DriverHistoryCard({
  driverId,
  teams,
  id,
  pictures,
  season,
}) {
  const navigate = useNavigate();

  return (
    <Card>
      <CardBody>
        <KeyValue title="Id" values={[{ value: id }]} />
        <KeyValue title="DriverId" values={[{ value: driverId }]} />
        {/*<KeyValue title="FirstName" values={[{ value: firstName }]} />*/}
        {/*<KeyValue title="LastName" values={[{ value: lastName }]} />*/}
        <KeyValue
          title="Season"
          values={[{ value: season.id }, { value: season.year }]}
        />
        <KeyValue
          title="Teams"
          values={teams.map((team) => {
            return {
              value: `${team.id}`,
            };
          })}
        />
        <KeyValue
          title="Images"
          values={pictures.map((picture) => {
            return {
              value: picture.pictureType,
              link: picture.picture,
              isImage: true,
            };
          })}
        />
      </CardBody>
      <CardFooter>
        <Button
          size="medium"
          variant="contained"
          onClick={() => navigate(`/drivers/${driverId}/histories/${id}/edit`)}
        >
          Update history
        </Button>
      </CardFooter>
    </Card>
  );
}
