import { useNavigate, useParams } from "react-router-dom"

import ListPageContainer from "components/Shared/ListPageContainer"
import LoadingProgress from "components/Shared/LoadingProgress"
import TeamHistoriesList from "components/TeamHistoriesList"
import useTeam from "core/hooks/useTeam"
import useTeamHistories from "core/hooks/useTeamHistories"

export default function TeamHistoriesPage() {
  const navigate = useNavigate()

  const { teamId } = useParams()

  const { histories, isLoading, totalCount } = useTeamHistories(teamId)

  const { team, isLoading: isLoadingTeam } = useTeam(teamId)

  if (isLoading || isLoadingTeam) {
    return <LoadingProgress />
  }

  return (
    <ListPageContainer
      title={`Histories of ${team.name}: ${totalCount}`}
      buttonText={"Add new histories"}
      handleButton={() => navigate(`/teams/${teamId}/create-team-histories`)}
      navs={[
        { link: `/teams`, title: "Teams" },
        {
          link: `/teams/${teamId}/edit`,
          title: teamId,
        },
      ]}
    >
      <TeamHistoriesList histories={histories} />
    </ListPageContainer>
  )
}
