import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"

import GrandPrixScheduleEdit from "components/GrandPrixScheduleEdit"
import moment from "moment"

export default function GrandPrixSchedules({ gpId, schedules }) {
  if (schedules == null) {
    return
  }

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Order</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((schedule) => (
            <TableRow
              key={schedule.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {schedule.type}
              </TableCell>
              <TableCell component='th' scope='row'>
                {schedule.order}
              </TableCell>
              <TableCell component='th' scope='row'>
                {`${moment(schedule.startDate).utc().format("LLL")} - 
                ${moment(schedule.endDate).utc().format("LT")}`}
              </TableCell>
              <TableCell component='th' scope='row'>
                <GrandPrixScheduleEdit gpId={gpId} schedule={schedule} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
