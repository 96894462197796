import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const grandsPrix = {
  getGrandsPrix: (pageNumber, pageSize, token) => {
    return apiService
      .getData(
        `v1/grands-prix?pageNumber=${pageNumber}&pageSize=${pageSize}`,
        token
      )
      .then(responseToJson)
  },
  getGrandsPrixBySeason: (seasonId, token) => {
    return apiService
      .getData(
        `v1/grands-prix?pageNumber=${1}&pageSize=${100}&seasonId=${seasonId}`,
        token
      )
      .then(responseToJson)
  },
  getGrandPrixById: (gpId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}`, token)
      .then(responseToJson)
  },
  getRaceById: (gpId, raceId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}/races/${raceId}`, token)
      .then(responseToJson)
  },
  updateGrandPrix: (gpId, body, token) => {
    return apiService.patch(
      `v1/grands-prix/${gpId}`,
      JSON.stringify(body),
      token
    )
  },
  updateRace: (gpId, raceId, body, token) => {
    return apiService.patch(
      `v1/grands-prix/${gpId}/races/${raceId}/participations`,
      JSON.stringify(body),
      token
    )
  },
  getChassisByRaceById: (gpId, raceId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}/races/${raceId}/chassis`, token)
      .then(responseToJson)
  },
  getEnginesByRaceById: (gpId, raceId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}/races/${raceId}/engines`, token)
      .then(responseToJson)
  },
  getPracticeById: (gpId, practiceId, token) => {
    return apiService
      .getData(`v1/grands-prix/${gpId}/practices/${practiceId}`, token)
      .then(responseToJson)
  },
  updatePractice: (gpId, practiceId, body, token) => {
    return apiService.patch(
      `v1/grands-prix/${gpId}/practices/${practiceId}`,
      JSON.stringify(body),
      token
    )
  },
}
