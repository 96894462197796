import { Button } from "@mui/material"

export default function CtaButtonPrimary({
  onClick,
  label,
  color = "primary",
}) {
  return (
    <Button
      variant='contained'
      color={color}
      onClick={onClick}
      style={{ width: "100%" }}
    >
      {label}
    </Button>
  )
}
