import { Container, Grid, Typography } from "@mui/material";

import DriverHistoryCard from "../../components/DriverHistoryCard";
import LoadingProgress from "../../components/Shared/LoadingProgress";
import Navigation from "../../components/Navigation/Index";
import React from "react";
import useDriver from "core/hooks/useDriver";
import useDriverHistories from "core/hooks/useDriverHistories";
import { useParams } from "react-router-dom";

export default function DriverHistoriesPage() {
  const params = useParams();
  const { histories, isLoading } = useDriverHistories(params.driverId);

  const { driver } = useDriver(params.driverId);

  if (isLoading || driver == null) {
    return <LoadingProgress />;
  }

  console.log(histories)

  return (
    <Container>
      <Grid
        container
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={5}
      >
        <Grid item xs={12}>
          <Typography variant="h2">
            {driver.firstName} {driver.lastName} - Histories
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation
            navs={[
              { link: `/drivers/`, title: "Drivers" },
              {
                link: `/drivers/${params.driverId}/edit`,
                title: params.driverId,
              },
            ]}
          />
        </Grid>
        {histories.map((item) => {
          return (
            <Grid key={item.id} item xs={12}>
              <DriverHistoryCard
                key={item.id}
                driverId={item.driverId}
                id={item.id}
                teams={item.teams}
                pictures={item.pictures}
                season={item.season}
              />
            </Grid>
          );
        })}
      </Grid>
    </Container>
  );
}
