import { Button } from "@mui/material"

export default function CtaButtonSecondary({
  onClick,
  label,
  color = "primary",
}) {
  return (
    <Button
      variant='outlined'
      color={color}
      onClick={onClick}
      style={{ width: "100%" }}
    >
      {label}
    </Button>
  )
}
