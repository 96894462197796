import { Alert, Button, Grid } from "@mui/material"

import FormContainer from "../FormContainer"
import { GridCenter } from "ds/molecules"
import Navigation from "components/Navigation/Index"
import TitlePage from "ds/atoms/TitlePage"

export default function FormPageContainer({
  title,
  isError,
  buttonText,
  handleButton,
  handleSubmit,
  navigations,
  children,
}) {
  return (
    <Grid container rowSpacing={2}>
      <GridCenter>
        <TitlePage title={title} />
      </GridCenter>

      <Grid item xs={12}>
        {isError && <Alert severity='error'>Error during the add</Alert>}
      </Grid>

      {handleButton && (
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
          alignSelf='stretch'
        >
          <Button variant='contained' onClick={handleButton}>
            {buttonText}
          </Button>
        </Grid>
      )}

      {navigations?.length > 0 && (
        <Grid item xs={12}>
          <Navigation navs={navigations} />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormContainer handleSubmit={handleSubmit}>{children}</FormContainer>
      </Grid>
    </Grid>
  )
}
