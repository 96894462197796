import { FormGroup, TextField } from "@mui/material"

import { Label } from "ds/atoms"

export default function InputGroup({
  id,
  label,
  value,
  disabled = false,
  autocomplete,
  onChange,
}) {
  if (disabled) {
    return (
      <FormGroup>
        <Label id={id} label={label} />
        <TextField
          id={id}
          label={label}
          defaultValue={value}
          fullWidth
          disabled
          inputProps={
            autocomplete
              ? ""
              : {
                  "data-form-type": "other",
                  "data-lpignore": "true",
                }
          }
        />
      </FormGroup>
    )
  }

  return (
    <FormGroup>
      <Label id={id} label={label} />
      <TextField
        id={id}
        label={label}
        value={value}
        fullWidth
        onChange={(event) => onChange(event.target.value)}
      />
    </FormGroup>
  )
}
