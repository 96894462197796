import { Grid } from "@mui/material"

export default function GridCenter({ children }) {
  return (
    <Grid
      container
      display='flex'
      justifyContent='center'
      alignItems='center'
      alignSelf='stretch'
      padding={2}
    >
      {children}
    </Grid>
  )
}
