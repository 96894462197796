import { drivers } from "core/api/drivers"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useDriverHistories(driverId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["driver-histories", { driverId }], () =>
    getToken().then((token) => {
      return drivers.getDriversHistoriesByDriverId(driverId, token)
    })
  )

  console.log(queryResponse?.data)

  return {
    ...queryResponse,
    histories: queryResponse?.data?.items ?? [],
  }
}

export const histories = {}
