import apiService from "core/services/network/api"
import responseToJson from "core/utils/reponseToJson"

export const teams = {
  getTeams: (page, pageSize, token) => {
    return apiService
      .getData(
        `v1/teams?pageNumber=${page}&pageSize=${pageSize}&sortBy=asc(ShortName)`,
        token
      )
      .then(responseToJson)
  },
  getTeamById: (teamId, token) => {
    return apiService.getData(`v1/teams/${teamId}`, token).then(responseToJson)
  },
  addTeam: (body, token) => {
    return apiService
      .create(`v1/teams`, JSON.stringify(body), token)
      .then(responseToJson)
  },
  updateTeam: (id, body, token) => {
    return apiService.patch(`v1/teams/${id}`, JSON.stringify(body), token)
  },
  deleteTeam: (id, token) => {
    return apiService.delete(`v1/teams/${id}`, null, token)
  },
  getTeamHistories: (teamId, token) => {
    return apiService
      .getData(`v1/teams/${teamId}/histories?sortBy=name`, token)
      .then(responseToJson)
  },
  getTeamHistoryById: (teamId, historyId, token) => {
    return apiService
      .getData(`v1/teams/${teamId}/histories/${historyId}`, token)
      .then(responseToJson)
  },
  addTeamHistories: (teamId, body, token) => {
    return apiService.create(
      `v1/teams/${teamId}/histories`,
      JSON.stringify(body),
      token
    )
  },
  updateTeamHistory: (teamId, historyId, body, token) => {
    return apiService.patch(
      `v1/teams/${teamId}/histories/${historyId}`,
      JSON.stringify(body),
      token
    )
  },
  deleteTeamHistory: (teamId, historyId, token) => {
    return apiService.delete(
      `v1/teams/${teamId}/histories/${historyId}`,
      null,
      token
    )
  },
}
