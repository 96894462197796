import { grandsPrix } from "core/api/grandsPrix"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function usePractice(gpId, practiceId) {
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["practices", { gpId, practiceId }], () =>
    getToken().then((token) => {
      return grandsPrix.getPracticeById(gpId, practiceId, token)
    })
  )

  const updatePractice = async (body) => {
    const token = await getToken()
    return grandsPrix.updatePractice(gpId, practiceId, body, token)
  }

  return {
    ...queryResponse,
    practice: queryResponse.data,
    updatePractice,
  }
}
