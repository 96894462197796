import { seasons } from "core/api/seasons"
import { useAuth0 } from "@auth0/auth0-react"
import { useQuery } from "react-query"

export default function useSeasonTeams(seasonId) {
  const { getAccessTokenSilently } = useAuth0()

  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["season-teams", { seasonId }], () =>
    getToken().then((token) => {
      if (seasonId == null) {
        return
      }
      return seasons.getSeasonTeams(seasonId, token)
    })
  )

  return {
    ...queryResponse,
    teams: queryResponse?.data?.items ?? [],
  }
}
