import { Alert, Container, FormControl, Grid, TextField } from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import { CtaButtonPrimary } from "ds/atoms"
import GrandPrixSchedules from "components/GrandPrixSchedules"
import { GridCenter } from "ds/molecules"
import LoadingProgress from "components/Shared/LoadingProgress"
import Navigation from "components/Navigation/Index"
import React from "react"
import TitlePage from "ds/atoms/TitlePage"
import formValue from "core/utils/formValue"
import useGrandPrix from "core/hooks/useGrandPrix"
import { useParams } from "react-router-dom"

export default function GrandPrixEditPage() {
  const params = useParams()
  const gpId = params.gpId

  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)

  const [officialName, setOfficialName] = React.useState("")
  const [round, setRound] = React.useState(null)

  const { grandPrix, refetch, isLoading, updateGrandPrix } = useGrandPrix(gpId)

  React.useEffect(() => {
    if (grandPrix != null) {
      setRound(grandPrix.round)
      setOfficialName(grandPrix.officialName)
    }
  }, [grandPrix])

  if (isLoading) {
    return <LoadingProgress />
  }

  const handleSubmit = () => {
    updateGrandPrix({
      round,
      officialName,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  return (
    <Container>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <GridCenter>
          <TitlePage title='Update Grand Prix' />
        </GridCenter>

        <Grid item xs={12}>
          <Navigation
            navs={[{ link: `/grands-prix/`, title: "Grands Prix" }]}
          />
        </Grid>
        <Grid item xs={12}>
          {successDisplay && (
            <Alert
              icon={<CheckCircleOutline fontSize='inherit' />}
              severity='success'
            >
              This is a success Alert.
            </Alert>
          )}
          {errorDisplay && (
            <Alert severity='error'>This is a error Alert.</Alert>
          )}
        </Grid>
      </Grid>

      <FormControl fullWidth>
        <Grid
          container
          columnSpacing={2}
          paddingRight={6}
          paddingLeft={6}
          paddingBottom={2}
          paddingTop={2}
          rowSpacing={2}
        >
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpId'
              label='Id'
              defaultValue={grandPrix.id}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpName'
              label='Name'
              defaultValue={grandPrix.name}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpStartDate'
              label='Start Date'
              defaultValue={grandPrix.startDate}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpEndDate'
              label='End Date'
              defaultValue={grandPrix.endDate}
              fullWidth
              disabled
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpRound'
              label='Round'
              defaultValue={grandPrix.round}
              type='number'
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setRound(formValue(event.target.value))
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <TextField
              id='gpOfficialName'
              label='Official Name'
              defaultValue={grandPrix.officialName}
              fullWidth
              onChange={(event) => {
                setSuccessDisplay(false)
                setErrorDisplay(false)
                setOfficialName(formValue(event.target.value))
              }}
            />
          </Grid>
          <GridCenter>
            <CtaButtonPrimary label={"Save"} onClick={handleSubmit} />
          </GridCenter>
        </Grid>
      </FormControl>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <GrandPrixSchedules gpId={gpId} schedules={grandPrix.schedule} />
      </Grid>
    </Container>
  )
}
