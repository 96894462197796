import { useQuery, useQueryClient } from "react-query"

import { teams } from "core/api/teams"
import { useAuth0 } from "@auth0/auth0-react"

export default function useTeamHistories(teamId) {
  const queryClient = useQueryClient()
  const { getAccessTokenSilently } = useAuth0()
  const getToken = async () => {
    const token = await getAccessTokenSilently()
    return token
  }

  const queryResponse = useQuery(["team-histories", { teamId }], () =>
    getToken().then((token) => {
      return teams.getTeamHistories(teamId, token)
    })
  )

  const addTeamHistories = async (body) => {
    const token = await getToken()
    return teams.addTeamHistories(teamId, body, token).then((response) => {
      queryClient.removeQueries("team-histories")
      return response
    })
  }

  return {
    ...queryResponse,
    histories: queryResponse?.data?.items ?? [],
    totalCount: queryResponse?.data?.histories?.length ?? 0,
    addTeamHistories,
  }
}
