import { CtaButtonPrimary, CtaButtonSecondary } from "ds/atoms"

import { Grid } from "@mui/material"

export default function ActionTableCell({ actions }) {
  return (
    <Grid container direction={"column"} rowSpacing={0.5}>
      {actions.map((action, index) => {
        return (
          <Grid item key={index} xs={8}>
            {action.variant === "contained" ? (
              <CtaButtonPrimary
                color={action.color}
                onClick={action.onClick}
                label={action.title}
              />
            ) : (
              <CtaButtonSecondary
                color={action.color}
                onClick={action.onClick}
                label={action.title}
              />
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}
