import { CtaButtonSecondary } from "ds/atoms"
import { useNavigate } from "react-router-dom"

export default function GrandPrixScheduleEdit({ gpId, schedule }) {
  const navigate = useNavigate()

  if (schedule.type === "MainRace" || schedule.type === "SprintRace") {
    return (
      <CtaButtonSecondary
        label={"Edit"}
        onClick={() =>
          navigate(`/grands-prix/${gpId}/races/${schedule.id}/edit`)
        }
      />
    )
  }

  if (schedule.type === "Qualifying") {
    return (
      <CtaButtonSecondary
        label={"Edit"}
        onClick={() =>
          navigate(`/grands-prix/${gpId}/qualifyings/${schedule.id}/edit`)
        }
      />
    )
  }

  if (
    schedule.type === "FirstPractice" ||
    schedule.type === "SecondPractice" ||
    schedule.type === "ThirdPractice"
  ) {
    return (
      <CtaButtonSecondary
        label={"Edit"}
        onClick={() =>
          navigate(`/grands-prix/${gpId}/practices/${schedule.id}/edit`)
        }
      />
    )
  }

  return
}
