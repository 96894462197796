import {
  Alert,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"

import { CheckCircleOutline } from "@mui/icons-material"
import LoadingProgress from "components/Shared/LoadingProgress"
import Navigation from "components/Navigation/Index"
import RaceParticipationEdit from "components/RaceParticipationEdit"
import React from "react"
import { set } from "core/utils/setValue"
import useAllConstructorManufacturers from "core/hooks/useAllConstructorManufacturers"
import useAllEngineManufacturers from "core/hooks/useAllEngineManufacturers"
import { useParams } from "react-router-dom"
import useRace from "core/hooks/useRace"
import useRaceChassis from "core/hooks/useRaceChassis"
import useRaceEngines from "core/hooks/useRaceEngines"
import useSeasonTeams from "core/hooks/useSeasonTeams"
import useTyreManufacturers from "core/hooks/useTyreManufacturers"

export default function RaceEditPage() {
  const params = useParams()
  const gpId = params.gpId
  const raceId = params.raceId

  const [successDisplay, setSuccessDisplay] = React.useState(false)
  const [errorDisplay, setErrorDisplay] = React.useState(false)
  const [participations, setParticipations] = React.useState([])

  const { race, refetch, isLoading, updateRaceParticipations } = useRace(
    gpId,
    raceId
  )

  const { teams, isLoading: isLoadingTeams } = useSeasonTeams(race?.seasonId)
  const { tyreManufacturers, isLoading: isLoadingTyreManufacturers } =
    useTyreManufacturers()
  const { engineManufacturers } = useAllEngineManufacturers()
  const { constructorManufacturers } = useAllConstructorManufacturers()

  const {
    chassis,
    addChassis,
    isLoading: isLoadingChassis,
  } = useRaceChassis(gpId, raceId)
  const {
    engines,
    addEngines,
    isLoading: isLoadingEngines,
  } = useRaceEngines(gpId, raceId)

  React.useEffect(() => {
    if (race != null) {
      setParticipations(race.participations)
    }
  }, [race])

  const updateParticipation = (participationId, field, value) => {
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, field, value)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateTeam = (participationId, teamId) => {
    const team = teams.find((t) => t.teamId === teamId)
    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.id", team.teamId)
        set(p, "team.name", team.shortName)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateChassisManufacturer = async (participationId, value) => {
    const chassisManufacturer = constructorManufacturers.find(
      (e) => e.id === value
    )

    addChassis(value).then(() => {
      const c = chassis.filter((c) => c.constructorManufacturerId === value)

      const participationsUpdated = participations.map((p) => {
        if (p.id === participationId) {
          set(p, "team.constructorManufacturer.id", chassisManufacturer.id)
          set(p, "team.constructorManufacturer.name", chassisManufacturer.name)
          set(p, "team.constructorManufacturer.chassisId", c[0].id)
          set(p, "team.constructorManufacturer.chassisName", c[0].name)
        }
        return p
      })

      setParticipations(participationsUpdated)
    })
  }

  const updateEngineManufacturer = async (participationId, value) => {
    const engineManufacturer = engineManufacturers.find((e) => e.id === value)

    addEngines(value).then(() => {
      const enginesFromManufacturer = engines.filter(
        (e) => e.engineManufacturerId === value
      )

      const participationsUpdated = participations.map((p) => {
        if (p.id === participationId) {
          set(p, "team.engineManufacturer.id", engineManufacturer.id)
          set(p, "team.engineManufacturer.name", engineManufacturer.name)
          if (enginesFromManufacturer.length > 0) {
            set(
              p,
              "team.engineManufacturer.engineId",
              enginesFromManufacturer[0].id
            )
            set(
              p,
              "team.engineManufacturer.engineName",
              enginesFromManufacturer[0].name
            )
          } else {
            set(p, "team.engineManufacturer.engineId", "")
            set(p, "team.engineManufacturer.engineName", "")
          }
        }
        return p
      })

      setParticipations(participationsUpdated)
    })
  }

  const updateTeamTyreManufacturer = (participationId, value) => {
    const tyreManufacturer = tyreManufacturers.find((t) => t.id === value)

    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.tyreManufacturer.id", tyreManufacturer.id)
        set(p, "team.tyreManufacturer.name", tyreManufacturer.name)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateTeamChassis = (participationId, value) => {
    const c = chassis.find((t) => t.id === value)

    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.constructorManufacturer.chassisId", c.id)
        set(p, "team.constructorManufacturer.chassisName", c.name)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const updateTeamEngine = (participationId, value) => {
    const engine = engines.find((t) => t.id === value)

    const participationsUpdated = participations.map((p) => {
      if (p.id === participationId) {
        set(p, "team.engineManufacturer.engineId", engine.id)
        set(p, "team.engineManufacturer.engineName", engine.name)
      }
      return p
    })

    setParticipations(participationsUpdated)
  }

  const handleSubmit = () => {
    updateRaceParticipations({
      participations,
    }).then((response) => {
      if (response.ok === true) {
        setSuccessDisplay(true)
        refetch()
      } else {
        setErrorDisplay(true)
      }
    })
  }

  if (
    isLoading ||
    isLoadingTeams ||
    isLoadingTyreManufacturers ||
    isLoadingChassis ||
    isLoadingEngines
  ) {
    return <LoadingProgress />
  }

  return (
    <>
      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Typography variant='h2'>Edit Race</Typography>
        </Grid>
        <Grid item xs={12}>
          <Navigation
            navs={[
              { link: `/grands-prix`, title: "Grands Prix" },
              { link: `/grands-prix/${gpId}/edit`, title: gpId },
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          {successDisplay && (
            <Alert
              icon={<CheckCircleOutline fontSize='inherit' />}
              severity='success'
            >
              This is a success Alert.
            </Alert>
          )}
          {errorDisplay && (
            <Alert severity='error'>This is a error Alert.</Alert>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        columnSpacing={2}
        paddingRight={6}
        paddingLeft={6}
        paddingBottom={2}
        paddingTop={2}
        rowSpacing={2}
      >
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpId'
            label='gp id'
            defaultValue={race.grandPrixId}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpName'
            label='gp name'
            defaultValue={race.grandPrixName}
            fullWidth
            disabled
          />
        </Grid>

        <Grid
          item
          xs={6}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='gpRound'
            label='gp round'
            defaultValue={race.grandPrixRound}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceId'
            label='Id'
            defaultValue={race.id}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceType'
            label='Type'
            defaultValue={race.type}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceStartDate'
            label='Start Date'
            defaultValue={race.date}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <TextField
            id='raceEndDate'
            label=' End Date'
            defaultValue={race.endDate}
            fullWidth
            disabled
          />
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          alignSelf='stretch'
        >
          <Table stickyHeader aria-label='simple table'>
            <TableHead>
              <TableRow>
                <TableCell size='small'>Pos</TableCell>
                <TableCell>Grid / Laps / Points</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>DriverId</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Material</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {race.participations.map((participation) => (
                <RaceParticipationEdit
                  key={participation.id}
                  participation={participation}
                  updateParticipation={updateParticipation}
                  updateTeam={updateTeam}
                  updateTeamChassisManufacturer={updateChassisManufacturer}
                  updateTeamEngineManufacturer={updateEngineManufacturer}
                  updateTeamTyreManufacturer={updateTeamTyreManufacturer}
                  setErrorDisplay={setErrorDisplay}
                  setSuccessDisplay={setSuccessDisplay}
                  teams={teams}
                  tyreManufacturers={tyreManufacturers}
                  chassisManufacturers={constructorManufacturers}
                  engineManufacturers={engineManufacturers}
                  chassis={chassis.filter(
                    (c) =>
                      c.constructorManufacturerId ===
                      participation.team.constructorManufacturer.id
                  )}
                  engines={engines.filter(
                    (e) =>
                      e.engineManufacturerId ===
                      participation.team.engineManufacturer.id
                  )}
                  updateTeamChassis={updateTeamChassis}
                  updateTeamEngine={updateTeamEngine}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid
          item
          xs={12}
          display='flex'
          justifyContent='center'
          alignItems='center'
          alignSelf='stretch'
        >
          <Button size='medium' variant='contained' onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
