import { Container, Grid } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"

import FormPageContainer from "components/Shared/FormPageContainer"
import InputGroup from "components/Shared/InputGroup"
import React from "react"
import useGrandPrix from "core/hooks/useGrandPrix"
import usePractice from "core/hooks/usePractice"

export default function PracticeEditPage() {
  const params = useParams()
  const gpId = params.gpId
  const practiceId = params.practiceId

  const navigate = useNavigate()

  const [startDate, setStartDate] = React.useState("")
  const [endDate, setEndDate] = React.useState("")

  const { practice, refetch, updatePractice } = usePractice(gpId, practiceId)
  const { refetch: refetchGp } = useGrandPrix(gpId)

  React.useEffect(() => {
    if (practice != null) {
      setStartDate(practice.startDate)
      setEndDate(practice.endDate)
    }
  }, [practice])

  const handleSubmit = (event) => {
    event.preventDefault()
    updatePractice({
      id: practiceId,
      startDate,
      endDate,
      grandPrixId: gpId,
    }).then((response) => {
      if (response.ok === true) {
        refetch()
        refetchGp()
        navigate(`/grands-prix/${gpId}/edit`)
      }
    })
  }

  return (
    <Container>
      <FormPageContainer handleSubmit={handleSubmit} title={"Update practice"}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <InputGroup
              id={`start-date`}
              label='Start Date'
              value={startDate}
              gridSize={12}
              onChange={(value) => {
                setStartDate(value)
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <InputGroup
              id={`end-date`}
              label='End Date'
              value={endDate}
              gridSize={12}
              onChange={(value) => setEndDate(value)}
            />
          </Grid>
        </Grid>
      </FormPageContainer>
    </Container>
  )
}
