import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"

import ActionTableCell from "components/Shared/ActionTableCell"
import DisplayGroup from "components/Shared/DisplayGroup"
import NoDataToDisplay from "components/Shared/NoDataToDisplay"
import { useNavigate } from "react-router-dom"

export default function TeamHistoriesList({ histories }) {
  const navigate = useNavigate()

  if (histories.length === 0) {
    return <NoDataToDisplay />
  }
  console.log(histories)

  return (
    <TableContainer sx={{ maxHeight: 900 }}>
      <Table stickyHeader aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell component='th'>Id</TableCell>
            <TableCell component='th'>Name</TableCell>
            <TableCell component='th'>Color</TableCell>
            <TableCell component='th'>Engine Manufacturer</TableCell>
            <TableCell component='th'>Chassis Manufacturer</TableCell>
            <TableCell component='th'>Tyre Manufacturer</TableCell>
            <TableCell component='th'>Season</TableCell>
            <TableCell component='th'>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {histories.map((history) => (
            <TableRow key={history.id}>
              <TableCell component='td'>
                <Typography component='span' style={{ fontSize: "12px" }}>
                  {history.id}
                </Typography>
              </TableCell>
              <TableCell component='td'>
                <DisplayGroup
                  groups={[
                    { title: "full name", value: history.fullName },
                    { title: "short name", value: history.shortName },
                  ]}
                />
              </TableCell>
              <TableCell component='td'>
                {history.color && (
                  <div className='flex-col'>
                    <Typography component='span' style={{ fontSize: "12px" }}>
                      {`#${history.color}`}
                    </Typography>
                    <span
                      className='color'
                      style={{ backgroundColor: `#${history.color}` }}
                    />
                  </div>
                )}
              </TableCell>
              <TableCell component='td'>
                {history.materialInformation.map((materialInformation) => (
                  <DisplayGroup
                    key={materialInformation.engineManufacturer.id}
                    groups={[
                      { title: "id", value: materialInformation.engineManufacturer.id},
                      { title: "name", value: materialInformation.engineManufacturer.name },
                    ]}
                  />
                ))}
              </TableCell>
              <TableCell component='td'>
                {history.materialInformation.map((materialInformation) => (
                    <DisplayGroup
                        key={materialInformation.chassisManufacturer.id}
                        groups={[
                          { title: "id", value: materialInformation.chassisManufacturer.id},
                          { title: "name", value: materialInformation.chassisManufacturer.name },
                        ]}
                    />
                ))}
              </TableCell>
              <TableCell component='td'>
                {history.materialInformation.map((materialInformation) => (
                    <DisplayGroup
                        key={materialInformation.tyreManufacturer.id}
                        groups={[
                          { title: "id", value: materialInformation.tyreManufacturer.id},
                          { title: "name", value: materialInformation.tyreManufacturer.name }
                        ]}
                    />
                ))}
              </TableCell>
              <TableCell component='td'>
                <DisplayGroup
                  groups={[
                    { title: "id", value: history.season.id },
                    { title: "year", value: history.season.year },
                  ]}
                />
              </TableCell>
              <TableCell component='td'>
                <ActionTableCell
                  actions={[
                    {
                      variant: "outlined",
                      title: "Edit",
                      onClick: () =>
                        navigate(
                          `/teams/${history.teamId}/histories/${history.id}/edit`
                        ),
                    },
                  ]}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
